(function() {
	/**
	 * @exports ASM.controllers.home
	 * @requires HBS
	 */
	let module = {};

	module.init = () => {
		console.log('Home.js initialized.')
	};

	module.examplePage = () => {

	};

	HBS.namespace('ASM.controllers.home', module);
}());